@tailwind base;
@tailwind components;
@tailwind utilities;

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* start */

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item-half {
  flex: 50%;
}

.exercise-thumbnail {
  border-radius: 25px;
}

.exercise-title-instructions {
  display: flex;
  flex-direction: column;
  padding: 6px 12px 12px;
}

.exercise-divider {
  display: none;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .flex-item-half {
    flex: 100%;
  }

  .exercise-title-instructions {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
  }

  .exercise-divider {
    display: block;
    margin-top: 24px !important;
    background: #d2bbbb;
  }
}
